.container {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  margin-top: 1rem;
  padding-inline: 1rem;

  div {
    min-width: 250px;
    max-width: 250px;
    display: flex;
    justify-content: space-between;

    p {
      font-size: 0.9rem;
      font-weight: 500;
      color: #474747;
    }
  }

  .paymentStatus {
    margin-top: 0.7rem;
    p {
      font-size: 0.9rem;
      font-weight: 500;
      color: #838383;
    }
    .badge {
      display: inline-block;
      padding: 0.35rem 0.65rem;
      font-size: 0.75em;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
    }
    .alertSuccess {
      border-radius: 50rem;
      color: #0f5132;
      background-color: #d1e7dd;
      border-color: #badbcc;
    }

    .alertPending {
      border-radius: 50rem;
      color: #664d03;
      background-color: #fff3cd;
      border-color: #ffecb5;
    }
  }
}
