.trending-products {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;

  .trending-content {
    width: 100%;
    @include tablet {
      width: 90%;
      margin-bottom: 0;
    }
  }
  h1 {
    font-size: clamp(1.5rem, 2vw, 1.7rem);
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
  }
}
