@import "../../../../../../ecommerce/styled-components/mixins.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-inline: 1rem;
  font-family: "Poppins", sans-serif;
  background-color: #fff;

  @include desktop {
    flex-direction: row;
    justify-content: space-around;
  }

  p {
    font-family: "Poppins", sans-serif;
    color: #a0a0a0;
    span {
      font-weight: 500;
      color: #747474;
    }
  }
}
