.virtual-consultancy-banner {
  position: relative;
  height: 45vh;
  width: 100%;
  overflow: hidden;

  @include tablet() {
    height: 70vh;
  }

  &__image {
    width: 100%;
    height: 100%;
    @include flexible(flex, row, nowrap, center, center);
    img {
      width: auto;
      height: 100%;
      object-fit: inherit;
      @include tablet() {
        object-fit: cover;
        height: auto;
        width: 100%;
      }
    }
  }

  .content {
    @include flexible(flex, column, nowrap, center, center);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    gap: 1rem;
    width: 80%;
    padding: 0.8rem;
    margin-top: 1rem;

    @include tablet() {
      @include flexible(flex, column, nowrap, center, flex-end);
      width: 50%;
      right: 5%;
      transform: translate(-5%, 10%);
    }

    &__text {
      background-color: rgba(135, 133, 133, 0.9);
      padding: 1rem;
      color: #fff;
      h3 {
        font-size: 1.4rem;
        line-height: normal;
        font-weight: 700;
        margin-bottom: 0.8rem;
      }
      p {
        font-size: 0.9rem;
        line-height: 1.5;
        color: #fff;
        font-weight: 400;
      }
    }
  }
}
