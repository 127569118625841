.productInfo {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #212121;
  font-size: 0.9rem;
  line-height: 1.2;

  .productImage {
    width: 3.5rem;
    height: auto;
    border-radius: 5px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .productName {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.2rem;
  }
}
