.banner-section {
  font-family: "Poppins";
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;

  &-content {
    width: 100%;
  }
  &-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1rem;
  }

  &-title {
    align-self: flex-start;
    font-weight: 700;
    font-size: 1.3rem;
    color: var(--text-color);
    margin-bottom: 0.5rem;
  }

  &-add-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    overflow: hidden;
    margin-block: 1rem;

    input[type="file"] {
      display: none;
    }
  }
  &-add-button__hidden {
    display: none;
  }

  &-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;

    @include tablet {
      justify-content: flex-start;
    }
  }

  &-item {
    position: relative;
    width: 100%;
    max-width: 250px;
    img {
      display: block;
      width: 100%;
      height: 100px;
      object-fit: cover;
      border: 1px solid #ccc;
      border-radius: 5px;
    }

    &:hover .banner-section-delete-button {
      opacity: 1;
    }
  }

  &-delete-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(0, 0, 0, 0.6);
    border: none;
    color: white;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }

    svg {
      font-size: 1rem;
    }
  }

  .empty-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 2rem;

    &-image {
      max-width: 150px;
      margin-bottom: 20px;
    }

    &-title {
      font-size: 18px;
      margin-bottom: 10px;
      color: var(--text-color);
      font-weight: 600;

      @include tablet {
        font-size: 1.4rem;
      }
    }

    &-description {
      font-size: 0.8rem;
      color: #6c757d;
      margin-bottom: 20px;

      @include tablet {
        font-size: 1rem;
      }
    }
  }
}
