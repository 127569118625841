.passwordValidationContainer {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  transition: all 0.2s ease-in-out;
  top: 3%;
  z-index: 1;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 0.2rem;
  &::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #ffffff;
  }

  .passwordValidation {
    li {
      display: flex !important;
      align-items: center !important;
      gap: 0.4rem;
    }
  }
}

.passwordValidationContainer.active {
  opacity: 1;
  visibility: visible;
}

.activeIcon {
  color: #7aa73e;
}
