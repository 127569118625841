.salesPersonForm {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include desktop {
    flex-direction: row;
    align-items: flex-start;
  }
}
