.products {
  position: relative;

  h1 {
    font-size: clamp(1.5rem, 2vw, 1.7rem);
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
    color: #666;
    border-bottom: 1px solid rgb(229 231 235);
    padding-bottom: 1.5rem;
  }

  .productsLayout {
    width: 100%;
    @include desktop {
      display: flex;
      align-items: flex-start;
    }

    .productsContent {
      min-height: 50vh;
      flex: auto;
      display: flex;
      flex-direction: column;
      @include desktop {
        min-height: 70vh;
      }
    }
  }
}
