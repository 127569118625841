.footer {
  padding-block: 4rem;
  background-color: var(--bg-color);
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid #d8d8d8;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 8rem;

    @media (max-width: 1000px) {
      margin: 0;
      padding: 0;
    }
  }

  &__row {
    display: flex;
    gap: 2rem;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 1.5rem;

    @include tablet {
      flex-direction: row;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__link {
    color: var(--text-color);
    font-size: 0.9375rem;
    text-decoration: none;
    width: fit-content;
    &:hover {
      text-decoration: underline;
    }
  }

  &__logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.7rem;

    img {
      height: 30px;
      @include tablet {
        height: 40px;
      }
    }
  }

  &__icon {
    color: var(--text-color);
    text-decoration: none;
    cursor: pointer;
    transition: 200ms ease-in;

    &:hover {
      opacity: 0.9;
    }

    > i {
      font-size: 2rem;
    }
  }

  &__icons-column {
    display: flex;
    gap: 2rem;
  }

  &__heading {
    font-size: 1.25rem;
    color: var(--text-color);
    font-weight: 700;
    margin-bottom: 1.5rem;
  }
}
