@import "../../styled-components/mixins.scss";

.jumbotron-container {
  width: 100%;
  position: relative;
  overflow: hidden;

  @include tablet() {
    height: auto;
  }

  .jumbotron-slider {
    img {
      width: 100%;
      height: auto;
      max-height: 600px;
      object-fit: cover;
    }
  }

  &__content {
    @include flexible(flex, column, nowrap, center, baseline);
    width: 80%;
    padding: 0.8rem;
    margin-top: 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    div:nth-of-type(1) {
      span {
        h1 {
          line-height: 2rem;
          text-align: center;
        }
        p {
          text-align: center;
        }
      }
    }
    div:nth-of-type(2) {
      margin-top: 1rem;
      width: 100%;
      @include flexible(flex, row, nowrap, center, baseline);
    }

    div:nth-child(1) {
      width: 100%;
      text-align: right;
    }

    @include tablet() {
      width: 60%;
    }
    @include desktop() {
      width: 50%;
      div:nth-of-type(1) {
        span {
          h1 {
            margin-bottom: 1rem;
            text-align: center;
          }
          p {
            text-align: center;
          }
        }
      }
    }
  }
}

/* ------ styles Jumbotron Slider  --------- */

.jumbotron-slider .slick-arrow {
  position: absolute;
  top: 47%;
  align-items: center;
  width: 44px !important;
  height: 44px !important;
  border-radius: 50% !important;
  font-family: FontAwesome;
  background: #ffff !important;
  z-index: 2 !important;
  transition: all 0.2s ease-in-out;
  border: 1px solid #eaeaea;
}

.jumbotron-slider .slick-arrow.slick-next {
  right: 2% !important;
}

.jumbotron-slider .slick-arrow.slick-prev {
  left: 2% !important;
}

.jumbotron-slider .slick-prev:before {
  content: "\f104" !important;
  font-family: FontAwesome !important;
  font-size: 30px !important;
  color: var(--primary-color) !important;
}

.jumbotron-slider .slick-next:before {
  content: "\f105" !important;
  font-family: FontAwesome !important;
  font-size: 30px !important;
  color: var(--primary-color) !important;
}

.jumbotron-slider .slick-arrow:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.062), 0 3px 6px rgba(0, 0, 0, 0.082) !important;
}

.jumbotron-slider .slick-arrow:hover::before {
  color: var(--primary-color) !important;
}

.jumbotron-slider .slick-dots {
  position: absolute;
  bottom: 30px !important;
}

.jumbotron-slider .slick-dots button::before {
  width: 10px !important;
  height: 10px !important;
  font-size: 12px !important;
  transition: all 0.2s ease-in-out;
}

.jumbotron-slider .slick-active button::before {
  color: var(--primary-color) !important;
}

.jumbotron-slider .slick-dots button:hover {
  transform: scale(1.05);
}

/***********************************************************/
