.main-slider .slick-arrow {
  position: absolute;
  top: 47%;
  align-items: center;
  width: 44px !important;
  height: 44px !important;
  border-radius: 50% !important;
  font-family: FontAwesome;
  background: #ffff !important;
  z-index: 2 !important;
  transition: all 0.2s ease-in-out;
  border: 1px solid #eaeaea;
}

.main-slider .slick-track {
  display: flex;
  gap: 1rem;
}
.main-slider .slick-arrow.slick-next {
  right: -5% !important;
}
.main-slider .slick-arrow.slick-prev {
  left: -5% !important;
}

.main-slider .slick-prev:before {
  content: "\f104" !important;
  font-family: FontAwesome !important;
  font-size: 30px !important;
  color: var(--primary-color) !important;
}
.main-slider .slick-next:before {
  content: "\f105" !important;
  font-family: FontAwesome !important;
  font-size: 30px !important;
  color: var(--primary-color) !important;
}

.main-slider .slick-arrow:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.062), 0 3px 6px rgba(0, 0, 0, 0.082) !important;
}
.main-slider .slick-arrow:hover::before {
  color: var(--primary-color) !important;
}

.main-slider .slick-dots {
  position: absolute;
  bottom: -30px !important;
}

.main-slider .slick-dots button::before {
  width: 10px !important;
  height: 10px !important;
  font-size: 12px !important;
  transition: all 0.2s ease-in-out;
}
.main-slider .slick-active button::before {
  color: var(--primary-color) !important;
}

.main-slider .slick-dots button:hover {
  transform: scale(1.05);
}
