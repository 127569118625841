// order
.order-container {
  display: flex;
  // justify-content: center;
  background-color: #606060;
  padding: 5vh 4rem;
  height: fit-content;
  @include media("<=tablet") {
    padding: 1rem 0rem;
  }
  // This fixes the last row (eye icon) being overflown
  .MuiTableCell-paddingNone:last-child {
    button {
      padding: 6px;
    }
  }
  .MuiTableCell-root {
    @include media("<=LGphone") {
      padding: 4px;
    }
  }
  .MuiPaper-root {
    @include media("<=LGphone") {
      font-size: 14px;
    }
  }
  .MuiSvgIcon-root,
  .material-icons {
    @include media("<=LGphone") {
      font-size: 18px;
    }
  }
  .MuiTableCell-root {
    @include media("<=LGphone") {
      min-height: 40px;
    }
  }
  .MuiPaper-root,
  .MuiTableCell-root {
    background-color: #e9e9e9 !important;
    box-shadow: none !important;
    .MuiTableCell-root {
      border-bottom: none;
    }
    .MuiSvgIcon-root {
      color: #606060;
    }
  }
  .order {
    display: flex;
    overflow: hidden;
    width: 90%;
    min-height: 80vh;
    margin: auto auto;
    background-color: #e9e9e9;
    border-radius: 1rem;
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    @include media("<=1040px") {
      flex-direction: column;
    }
    .main {
      flex: 3;
      background-color: $light-grey;
      width: 100%;
      .purchase-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include media("<=tablet") {
          flex-direction: column;
        }
      }
      .general-info {
        h1 {
          font-family: "Open sans";
          font-weight: 300;
          margin: 0.5rem 0 0.5rem 0;
        }
        p {
          font-family: "Roboto";
        }
      }
      .btn {
        background-color: #fdd400;
        color: black;
        padding: 0.8rem 3rem;
        border-radius: 10px;
        text-decoration: none;
        font-weight: 600;
        text-align: center;
        cursor: pointer;
        font-size: 1rem;
        font-family: "Open sans";
        border: none;
        @include media("<=tablet") {
          padding: 0.8rem 2rem;
          margin-top: 1rem;
        }
        &:disabled {
          cursor: not-allowed;
          border: 1px solid #999999;
          background-color: #cccccc;
          color: #666666;
        }
      }
      .image {
        height: 150px;
        width: 100%;
        object-fit: cover;
        margin: 20px 0 20px 0;
      }
      .status-info {
        // This change is so the background color of the <MaterialTable />
        // component matches our background in the remodeling resume page
        div[style*="background-color: rgba(255, 255, 255, 0.7);"] {
          background-color: rgba(233, 233, 233, 0.8) !important;
        }
        .display-none {
          display: none;
        }
        padding: 0 30px 10px 30px;
        @include media("<=tablet") {
          padding: 0;
        }
        #design_files,
        #budget_files {
          display: none;
        }
        h2 {
          margin: 0 0 10px;
        }
        .detail-panel-container {
          padding-left: 2rem;
          .detail-panel-row {
            padding: 0.5rem 1rem;
            display: grid;
            grid-template-columns: 1fr 1fr;
            .file-details {
              padding-left: 1rem;
              display: flex;
              gap: 0.6rem;
              align-items: center;
              i {
                margin-right: 1rem;
              }
            }
            .button-container {
              .button-see {
                cursor: pointer;
                padding: 5px 10px;
                border: none;
                background: #fdd400;
                font-family: "Open sans";
                font-weight: 600;
                color: #8a7e43;
                margin-right: 5px;
                text-decoration: none;
              }
              .button-goto-checkout {
                &:disabled {
                  cursor: not-allowed;
                  border: 1px solid #999999;
                  background-color: #cccccc;
                  color: #666666;
                }
                font-size: 1rem;
                cursor: pointer;
                padding: 8px 14px;
                border: none;
                border-radius: 10px;
                background: #fdd400;
                font-family: "Open sans";
                font-weight: 600;
                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
                  0 1px 2px 0 rgba(0, 0, 0, 0.06);
              }
              .button-approve {
                cursor: pointer;
                padding: 5px 10px;
                border: none;
                background: #ccddc3;
                font-family: "Open sans";
                font-weight: 600;
                color: #3d6548;
              }
            }
          }
        }
      }
    }
    .info {
      border-radius: 1rem;
      flex: 1;
      color: $dark_grey;
      background-color: white;
      padding: 2rem 4rem;
      font-weight: 300;
      @include media("<=1200px") {
        padding: 1rem 2rem;
      }
      .info-title {
        margin-bottom: 1.5rem;
      }
      p {
        text-align: justify;
        margin-bottom: 1rem;
        color: #adb0b1;
      }
      .social-icons {
        margin-top: 2rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
        i {
          font-size: 1.5rem;
          color: #adb0b1;
        }
      }
    }
  }
}

// Caption button inside the gallery (global for now, fix it if it causes conflicts)
.caption-button {
  cursor: pointer;
  padding: 5px 10px;
  border: none;
  background: $yellow;
  font-family: "Open sans";
  font-weight: 600;
  color: #3d6548;
}

// checkout.js
.checkout-selection {
  display: flex;
  flex-direction: column;
  .checkout-info {
    padding: 2rem 2rem 1rem 2rem;
    line-height: 1.8;
  }
}
.info {
  h1 {
    margin-bottom: 0.5rem;
    font-family: "Open sans";
    font-weight: 300;
  }
  h3 {
    margin: 0.25rem 0;
  }
  .btn {
    line-height: 40px;
    border-radius: 4px;
    padding: 0px 16px;
    color: white;
    cursor: pointer;
    margin: 0.25rem;
    font-size: 1rem;
    border: 0px;
    &:disabled {
      cursor: not-allowed;
      border: 1px solid #999999;
      background-color: #cccccc;
      color: #666666;
    }
  }
  .btn-wompi {
    background-color: rgb(26, 69, 148);
  }
  .btn-payu {
    background-color: #a5c312;
  }
}

.checkout-info-header {
  display: flex;
  align-items: center;
  .button-back-checkout {
    margin-right: 1rem;
    font-size: 1rem;
    cursor: pointer;
    padding: 8px 14px;
    border: none;
    border-radius: 100px;
    background: #fdd400;
    font-family: "Open sans";
    font-weight: 600;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
}
