.productCellContainer {
  display: flex;
  gap: 0.6rem;
}
.itemImage {
  height: 65px;
  min-width: 65px;
  position: static;
  width: 65px;
  img {
    border: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 6px;
    border-radius: 6px;
    height: auto;
    width: 100%;
  }
}
