@import "../../ecommerce/styled-components/mixins.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(51, 51, 51, 0.8);
  padding-inline: 10px;
  padding-block: 20px;
  width: 90%;
  max-width: 450px;
  margin-inline: auto;

  @include tablet() {
    padding-block: 30px;
    padding-inline: 30px;
    width: 100%;
  }

  .title {
    font-family: inherit;
    font-weight: 600;
    font-style: normal;
    font-size: 1.5rem;
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
  }

  .form {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 300px;

    .footerContent {
      margin-top: 16px;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
    }
  }
  .alreadyLogin {
    width: 100%;
    margin-top: 0.8rem;
    label {
      text-align: left;
      font-size: 14px;
      line-height: 1.5;
      color: rgba(255, 255, 255, 0.7);
      a,
      span {
        cursor: pointer;
        color: #fdd440;
        text-decoration: none;
        text-decoration: underline;
      }
    }
  }
}
