:root {
  --black-color: #000000;

  --bg-color: #ffffff;
  --bg-color-2: rgba(135, 133, 133, 0.9);
  --bg-color-3: #cacaca;
  --bg-color-4: rgba(245, 246, 249, 1);

  --main-color: #4f4f4f;

  --primary-color: #1c608c;
  --secondary-color: #54595f;

  --text-color: var(--main-color);
  --text-light-color: #ffffff;
  --text-muted: #9ca0a0;

  --track-color: #202324;
  --thumb-color: #454a4d;
  --thumb-hover-color: #596268;
}
