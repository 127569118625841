.navbarLinksForm {
  display: flex;
  flex-direction: column;
  padding-inline: 10px;
  padding-block: 20px;
  max-width: 450px;
  gap: 1rem;
  margin-inline: auto;
  font-family: "Poppins", sans-serif;
  p {
    font-size: 14px;
    font-weight: 400;
    color: var(--text-color);
  }
  .header {
    h2 {
      text-align: center;
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 1rem;
      color: var(--text-color);
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .buttonContainer {
    display: flex;
    gap: 0.5rem;
    padding-inline: 8px;
    margin-top: 1rem;

    @media screen and (min-width: 43rem) {
      gap: 1rem;
    }

    .buttonItem {
      width: 48%;
    }
  }
}
