.productEditNav {
  position: sticky;
  top: 64px;
  left: 0;
  right: 0;
  padding: 1.5rem;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 2;
  width: 100%;
  background-color: white;
  margin-left: -24px;
  margin-right: -24px;
  @media (max-width: 600px) {
    top: 55px;
  }
}

.productEditTabs {
  width: 100%;
  max-width: clamp(30px, 80vw, 600px);
  padding: 0;
  @media (min-width: 768px) {
    max-width: 100%;
  }
}

.productEditContent {
  display: flex;
  gap: 1rem;
}

.productEditText {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  span {
    color: rgb(145, 158, 171);
    font-size: small;
  }
  h4 {
    font-size: medium;
  }
}

.btnBack {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  min-width: 0;
  border-radius: 0.5rem;
  background-color: white;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 5%);
  cursor: pointer;

  svg {
    color: #252525;
  }
}
