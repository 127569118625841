/* TODO: Check the css and make it better */

.chat-header {
  background: #232f3e;
  border-bottom: 1px solid #e8e8e8;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.icon_tok {
  transition-duration: 1000ms;
}

.icon_tok:hover {
  transform: rotate(-45deg);

  cursor: pointer;
}

.blocked_icon_tok:hover {
  cursor: not-allowed;
}

.chat-header > span:hover {
  color: rgb(153, 149, 149);
}
.chat-header > p {
  padding: 0 2rem 0 1rem;
  color: #fff;
  font-weight: 700;
  line-height: 45px;
  align-self: center;
}

/* ********* Chat Body styled ********* */

.chat-body {
  background: rgba(229, 231, 233);
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: hidden;
  padding-top: 10px;
  padding-bottom: 20px;
}
.chat-body .message-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.chat-body .message-container::-webkit-scrollbar {
  display: none;
}
.chat-body .message {
  height: auto;
  padding: 5px 10px;
  display: flex;
}

.chat-body .message .message-content {
  width: auto;
  height: auto;
  min-height: 40px;
  max-width: 120px;
  background-color: #43a047;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-left: 5px;
  padding-right: 5px;
  padding-left: 5px;
  overflow-wrap: break-word;
  word-break: break-word;
}

#you {
  justify-content: flex-start;
}

#you .message-content {
  justify-content: flex-start;
}

#you .message-meta {
  justify-content: flex-start;
  margin-left: 5px;
}

#other {
  justify-content: flex-end;
}

#other .message-content {
  justify-content: flex-end;
  background-color: cornflowerblue;
}

#other .message-meta {
  justify-content: flex-end;
  margin-right: 5px;
}

.message-meta #author {
  margin-left: 10px;
  font-weight: bold;
}

.chat-body .message .message-meta {
  display: flex;
  font-size: 12px;
}

.message-background {
  padding: 1rem 1rem;
  background: rgba(253, 212, 64, 0.4);
  border-radius: 8px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
}

.message-background.own {
  background: rgba(94, 97, 95, 0.3);
}
.message__text {
  margin-bottom: 0;
  color: #363738;
}

/* ********* Chat Footer styled ********* */

.chat-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 4rem;
  background: #232f3e;
  border-top: 1px solid #e8e8e8;
}

.chat-footer > input {
  flex: 1;
  color: #fff;
  background: transparent;
  outline: none;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  border: 0px;
  height: 2rem;
}

.s-icon:hover {
  cursor: pointer;
}
