.header-checkout {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 4;
  background-color: var(--bg-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 1.5rem;
  padding-block: 1rem;
  text-align: center;
  box-shadow: 0 6px 3px -3px rgba(0, 0, 0, 0.16);

  @media (min-width: 1024px) {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .header-checkout-logo {
    height: 40px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    @include tablet {
      height: 50px;
      width: auto;
    }
    a {
      height: 100%;
      img {
        max-height: 100%;
        width: auto;
        object-fit: contain;
      }
    }
  }
}
