// landing
.landing {
  position: relative;
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  .overlay {
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  video {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    @include media("<=tablet") {
    }
  }
  .container {
    text-align: center;
    position: absolute;
    width: 35%;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    @include media("<=tablet") {
      width: 70%;
    }
    img {
      width: 100%;
      height: auto;
    }
    p {
      font-weight: 300;
      font-size: 0.8rem;
      color: white;
    }
  }
  a {
    position: absolute;
    top: 88%;
    z-index: 2;
  }
}

// signup after selecting your designer
.signup-user,
.designer-form {
  display: flex;
  width: 100%;
  background-color: $almost_white;
  @include media("<=tablet") {
    flex-direction: column;
  }
  .image {
    background-image: url("../images/living-room.jpg"),
      linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75));
    background-blend-mode: overlay;
    background-size: cover;
    flex: 1;
    img {
      padding: 20px 20px 20px 5px;
      position: relative;
      height: 64px;
    }
    .image-text {
      display: flex;
      flex-direction: column;
      margin: 5rem;
      font-weight: 600;
      p {
        text-decoration: underline;
        font-size: 0.8rem;
        color: $yellow;
      }
      h1 {
        font-family: "Open sans";
        margin-bottom: 5rem;
        font-size: 2rem;
        font-weight: 600;
        color: $almost_white;
      }
    }
  }
  // Designer form
  .form-2 {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    form {
      margin: 4rem;
    }
    .column {
      display: flex;
      flex-wrap: wrap;
    }
    input,
    textarea,
    select {
      flex-grow: 1;
      margin: 1rem 0.5rem;
      border: none;
      border-bottom: 1px solid black;
      background-color: $almost_white;
    }
    input:focus,
    textarea:focus {
      outline: 1px solid $yellow;
    }
    input::placeholder,
    textarea::placeholder {
      font-family: "Open sans";
      color: $dark_grey;
      text-align: center;
      font-size: 1rem;
    }
    select {
      font-family: "Open sans";
      font-size: 1rem;
      color: grey;
      width: 100%;
      border: none;
      text-align-last: center;
      background: none;
      border-bottom: 1px solid black;
    }
  }
  .form {
    min-height: 100vh;
    flex: 1;
    margin-top: 2.5rem;
    background-color: $almost_white;
    form {
      display: flex;
      padding: 4rem;
      flex-direction: column;
      align-items: center;
      @include media("<=phone") {
        padding: 2rem;
      }
    }
    select {
      font-family: "Open sans";
      font-size: 1rem;
      width: 100%;
      color: grey;
      border: none;
      margin: 1rem 0.5rem;
      text-align-last: center;
      background: none;
      border-bottom: 1px solid black;
    }
    input,
    textarea {
      margin: 1rem 0.5rem;
      width: 100%;
      padding: 0.25rem 1rem;
      border: none;
      border-bottom: 1px solid black;
      background-color: $almost_white;
    }
    input:focus,
    textarea:focus {
      outline: 1px solid $yellow;
    }
    input::placeholder,
    textarea::placeholder {
      font-family: "Open sans";
      color: $dark_grey;
      text-align: center;
      font-size: 1rem;
    }
    #tandc {
      width: auto;
    }
    .form-row {
      display: flex;
      width: 100%;
      align-items: center;
      i {
        margin-right: 1rem;
        font-size: 1.25rem;
      }
    }
    .form-row-wrap {
      display: flex;
      width: 100%;
      align-items: center;
      @include media("<=LGphone") {
        flex-wrap: wrap;
        // flex-direction: row;
      }
      i {
        margin-right: 1rem;
        font-size: 1.25rem;
      }
    }
    // Let's leave this as border-box since select's are a little tricky
    // to align with the rest of the input tags.
    // input,
    // select,
    // textarea {
    //   -ms-box-sizing: border-box;
    //   -moz-box-sizing: border-box;
    //   box-sizing: border-box;
    //   -webkit-box-sizing: border-box;
    // }
    //
    //2 columns aligned
    // .form-designers-1 {
    //   display: flex;
    //   justify-content: space-between;
    //   height: 65vh;
    // }
    // .form {
    // 	display: flex;
    // 	flex-direction: column;
    // }
    // .column {
    // 	display: flex;
    // 	flex-wrap: wrap;
    // }
    // input, textarea {
    // 	flex-grow: 1;
    // 	margin: 0.5rem;
    // }
    // @media (max-width: 800px) {
    //   .next {
    //     margin-top: 30%;
    //   }
    //   .form-designers-1 {
    //     display: block;
    //     .designers-col {
    //       width: 100%;
    //       margin: 0 0 10px 0;
    //     }
    //   }
    // }

    // .form-designers-2 {
    //   height: 55vh;
    // }
    //
    .form-designers-3 {
      display: flex;
      flex-direction: column;
      padding: 5rem;
      align-items: center;
    }
    //   p {
    //     margin-bottom: 1rem;
    //   }
    //   .error {
    //     text-align: center;
    //     color: red;
    //   }
    //   .action-buttons {
    //     margin-top: 15%;
    //     display: flex;
    //     justify-content: space-between;
    //   }
    // }
  }
}

// designer modal
.designer-modal {
  display: flex;
  justify-content: space-between;
  background-color: $almost_white;
  border-radius: 15px;
  max-height: 80vh;
  overflow: hidden;
  @include media("<=LGphone") {
    flex-direction: column;
    max-height: none;
  }
  .stars-container {
    color: rgba(96, 96, 96, 0.5);
  }
  .designer-info {
    display: flex;
    margin-top: 1rem;
    img {
      height: auto;
      object-fit: cover;
      width: 165px;
    }
  }
  button {
    background-color: $yellow;
    font-size: 1rem;
    color: black;
    padding: 0.5rem 3rem;
    cursor: pointer;
    border: none;
    border-radius: 12px;
    font-weight: 600;
  }
  .left-row {
    width: 60%;
    padding: 2rem 0 2rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    @include media("<=LGphone") {
      width: auto;
      padding: 2rem 0.5rem 1rem 0.5rem;
    }
    .header {
      display: flex;
      flex-direction: column;
      @include media("<=LGphone") {
        flex-direction: row;
        margin: 0 1rem;
      }
    }
    .profile-img-mobile {
      margin-right: 1rem;
      display: none;
      @include media("<=phone") {
        width: 100px;
        height: 150px;
        object-fit: cover;
        display: block;
      }
    }
    h1 {
      font-size: 3rem;
      letter-spacing: 1px;
      @include media("<=phone") {
        font-size: 2.5rem;
      }
    }
    .designer-info {
      .profile-img {
        min-height: 250px;
        @include media("<=phone") {
          display: none;
        }
      }
      p {
        text-align: justify;
        margin: 0 1rem;
        font-size: 1rem;
        //font-size: 0.8rem;
        color: grey;
        @include media("<=phone") {
          font-size: 1rem;
        }
      }
    }
    .button-container {
      display: flex;
      justify-content: center;
      margin-top: 1.5rem;
    }
  }
  .right-row {
    position: relative;
    width: 40%;
    overflow: hidden;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 15% 100%);
    @include media("<=LGphone") {
      clip-path: polygon(0 0, 100% 5%, 100% 100%, 0% 100%);
      width: 100%;
    }

    .slick-initialized,
    .slick-track,
    .slick-list,
    .slick-slide div,
    .slick-slide div img {
      height: 100%;
      .slick-slide div img {
        object-fit: cover;
        @include media("<=LGphone") {
          height: 300px;
        }
      }
    }
  }
}

// confirm address and designer modal
.signup-confirm-modal {
  .content {
    @include media(">=tablet") {
      max-width: 40vw !important;
    }
    .signup-confirm-content {
      margin-top: 40%;
      display: flex;
      flex-direction: column;
      padding: 2rem;
      #confirm-user-signup {
        margin-top: 1rem;
        background-color: #fdd400;
        color: black;
        padding: 0.5rem 1.5rem;
        border-radius: 10px;
        text-decoration: none;
        font-weight: 600;
        text-align: center;
        cursor: pointer;
        font-size: 1rem;
        font-family: "Open sans";
        border: none;
      }
    }
  }
}

.password-reset {
  // background: url(../images/living-room.jpg) no-repeat;
  background: url(https://cdn.pixabay.com/photo/2017/01/07/17/48/interior-1961070_1280.jpg)
    no-repeat;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(250, 201, 0, 0.8);
  font-family: "Roboto";
  display: flex;
  justify-content: center;
  background-color: #fdd400;
  min-height: 100vh;
  .password-reset-form {
    h1 {
      color: #505659;
    }
    p {
      color: #adb0b1;
    }
    input {
      font-size: 1rem;
      padding: 0.25rem 1rem;
      border: none;
      border-bottom: 1px solid #adb0b1;
      background-color: #f2f2f2;
    }
    box-shadow: 0 0.0625rem 0.125rem 0 rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    border-radius: 5px;
    margin-top: 5rem;
    margin-bottom: 5rem;
    background-color: #f2f2f2;
    padding: 3rem;
  }
}
