/*=============== Select Virtual and Presencial Designer ===============*/

.section__designer {
  background: #424242;
  width: 100%;
}
.designer__container {
  position: relative;
  max-width: 1024px;
  margin: 0 auto;
  scroll-behavior: smooth;
}

.designer__data {
  width: 100%;
  margin: 0 auto 2rem auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
}

.designer__data-options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0.2rem;
}

.designer__data select {
  padding: 0.5rem 0.8rem;
  border: none;
  border-radius: 12px;
  background: #fdd440;
  outline: none;
  cursor: pointer;
}

.designer__data p {
  font-size: 0.7rem;
  color: #bababa;
  margin-bottom: 1rem;
}

.designer__list {
  margin: 0 auto;
}

.button__questions {
  display: block;
  background: #fdd440;
  width: 55px;
  height: 55px;
  text-align: center;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.4);
  overflow: hidden;
}

.button__questions span {
  font-size: 1.4rem;
  font-weight: bold;
}

/* Arrows Slider Professionals */

.arrow__up {
  position: absolute;
  top: -10%;
  left: calc(50% - 40px);
  color: #fdd440;
  z-index: 2;
  cursor: pointer;
}

.arrow__down {
  position: absolute;
  bottom: -10%;
  left: calc(50% - 40px);
  color: #fdd440;
  z-index: 2;
  cursor: pointer;
}

/*=============== Home Login ===============*/

.login__background {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.35),
      rgba(0, 0, 0, 0.35)
    ),
    url(../../../assets/images/backgroundLogin.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 4.5rem 0 2rem;
  min-height: 90vh;
}

.container__form {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  margin: auto;
}

/*=============== Home Register ===============*/

.register__background {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.35),
      rgba(0, 0, 0, 0.35)
    ),
    url(../../../assets/images/backgroundRegister.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 4.5rem 0 2rem;
  min-height: 90vh;
}

/* Media Querys */

@media screen and (max-width: 767px) {
  .arrow__up,
  .arrow__down {
    visibility: hidden;
  }
}

@media screen and (min-width: 768px) {
  .designer__data {
    width: 90%;
    justify-content: flex-start;
  }
}
