.products {
  h2 {
    margin-left: 2rem;
  }
  .landing-image {
    display: flex;
    align-items: flex-end;
    width: 100%;
    min-height: 300px;
    background: url("../../assets/images/products-landing.jpg") no-repeat;
    background-size: cover;
    box-shadow: inset 0 0 0 1000px rgba(250, 201, 0, 0.8);
    h1 {
      color: white;
      font-size: 3rem;
      padding: 2rem;
      font-weight: 300;
    }
  }
  .search-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    input {
      width: 20rem;
      padding: .75rem 0;
      -webkit-transition: width .4s ease-in-out;
      transition: width .4s ease-in-out;
      float: right;
      margin: 0;
      border: none;
      border-bottom: 1px solid #616161;
      background: transparent;
      -webkit-box-shadow: none;
      box-shadow: none;
      color: #000;
    }
  }
}
