.customLink {
  display: flex;
  align-items: flex-end;
  gap: 0.2rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #0073bb;
  font-size: 0.9rem;
  line-height: 1.2;
  text-decoration-color: transparent;
  text-decoration: none;
  transition: all 0.5s ease-in;
  &:hover {
    text-decoration-color: #0073bb;
    text-decoration: underline;
  }
}
