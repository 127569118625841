@import "../../ecommerce/styled-components/mixins.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  position: fixed;
  inset: 0px;
  z-index: 50;
  outline: 2px solid transparent;
  outline-offset: 2px;
  background-color: rgb(38 38 38 / 0.7);

  .wraper {
    position: relative;
    width: 100%;
    height: 100%;
    margin-inline: auto;
    padding-block: 1rem;
    padding-inline: 1rem;
    min-height: 550px;
    transition: all 300ms ease;
    border-radius: 12px;
    background-color: var(--bg-color);
    align-items: flex-start;
    overflow-y: auto;
    @include flexbox(row, center, center);

    @include tablet() {
      width: 66.666667%;
      max-height: 90%;
      height: auto;
      align-items: flex-start;
    }
    @include desktop() {
      width: 50%;
      max-height: 90%;
      height: auto;
      align-items: flex-start;
    }

    .closeIcon {
      cursor: pointer;
      z-index: 100;
      position: fixed;
      width: fit-content;
      top: 0;
      right: 0;
      transition: all 300ms ease;
      padding: 0.25rem;
      border-width: 0px;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.body {
  flex: 1 1 auto;
  position: relative;
  overflow-y: auto;
  height: -webkit-fill-available;
  margin-top: 2rem;
}

.translatey100 {
  transform: translateY(20rem);
  opacity: 0;
  visibility: hidden;
}

.translatey0 {
  transform: translateY(0);
  opacity: 1;
}
