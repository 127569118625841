.documentActions {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    cursor: pointer;
  }
}

.iconAction {
  border: 1px solid #3f51b5;
  padding: 0.2rem;
}

.uploadedText {
  color: #212121;
  text-decoration: underline;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 5rem;
  &:hover {
    color: #a5a4a4;
  }
}

.notUploadedText {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #aaaaaa;
  min-width: 5rem;
}
