.preferences-container {
  font-family: "Poppins";
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;

  .preferences-heading {
    align-self: flex-start;
    font-weight: 700;
    font-size: 1.3rem;
    color: var(--text-color);
    margin-bottom: 1rem;
  }

  form {
    width: 100%;

    @include tablet {
      width: 90%;
    }

    .preferences-input-section {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 0.5rem;

      .preferences-subtitle {
        font-weight: 700;
        color: var(--text-color);
      }

      .preferences-description {
        font-size: 0.9rem;
        color: var(--text-color);
        font-weight: normal;
        margin-bottom: 1rem;
      }

      .color-content {
        display: flex;
        gap: 2rem;
        width: fit-content;

        input {
          visibility: hidden;
        }
        label {
          color: var(--text-color);
          font-size: 0.9rem;
          font-weight: 500;
        }

        .color-picker {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 0.7rem;
          border: 1px solid #ceccce;
          border-radius: 5px;
          width: 100%;
          padding-inline: 0.6rem;
          padding-block: 0.4rem;
          margin-top: 0.8rem;
          cursor: pointer;

          .color-picker-square {
            width: 20px;
            height: 20px;
            border-radius: 5px;
          }
        }
      }
    }
  }
}
