.selectInput {
  display: flex;
  align-items: center;

  .select {
    width: 200px;
    div {
      padding-block: 0.7rem;
    }
  }

  .selectIcon {
    color: #a8a8a8;
    margin-right: 8px;
  }
}
