@import "../../styled-components/mixins.scss";

.product-card {
  position: relative;
  @include flexible(flex, column, nowrap, center, center);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  .discount-badge {
    position: absolute;
    top: 0;
    left: 0;
    height: 36px;
    background-color: var(--primary-color);
    padding: 0.2rem;
    padding-inline: 0.8rem;
    display: flex;
    align-items: center;
    z-index: 1;

    span {
      font-size: 0.9rem;
      color: #ffffff;
      font-weight: 500;
    }
  }

  a {
    width: 100%;
    height: 100%;
  }

  &__image {
    position: relative;
    width: 100%;
    height: 10rem;
    div {
      background-color: #f4bf2a;
      position: absolute;
      padding-inline: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2rem;
      z-index: 1;
      top: 0;
      left: 0;
      span {
        align-self: center;
        font-size: 1rem;
        color: #ffffff;
        font-weight: 700;
      }
    }
    @include desktop {
      height: 15rem;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    position: relative;
    @include flexible(flex, column, nowrap, space-between, center);
    width: 100%;
    padding: 0.8rem;
    background-color: #f9f9f9;

    &__info {
      width: 100%;
      margin-bottom: 0.6rem;
      display: flex;
      flex-direction: column;

      .product-card-title {
        height: 36px;
        overflow: hidden;
        text-align: left;
        margin-bottom: 0.6rem;
        span {
          font-size: 14px;
          letter-spacing: 0.5px;
          line-height: 1.1rem;
          color: var(--text-color);
          font-weight: 400;
          display: inline-block;
        }
      }

      .price-container {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        height: fit-content;
      }

      .price {
        font-size: 0.9rem;
        letter-spacing: 0.5px;
        line-height: 1.1rem;
        color: var(--primary-color);
        font-weight: 400;
        display: inline-block;
      }

      .price-text {
        font-size: 0.9rem;
        color: var(--text-color);
        font-weight: 300;
      }

      p {
        font-size: 0.9rem;
        color: var(--primary-color);
        font-weight: 300;
        font-weight: 500;
      }
    }

    button {
      font-size: 0.8rem;

      :last-child {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      :last-child > * {
        margin-left: 5px;
        width: 15%;
      }
    }
  }
}
