@import "../../../../../ecommerce/styled-components/mixins.scss";

.orderHeader {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
  overflow: hidden;
  padding: 1.5rem;

  @include tablet {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-inline: 2rem;
  }

  div {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    h3 {
      line-height: 1;
      flex-shrink: 0;
      float: left;
      font-size: 14px;
      margin: 0;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.9);
    }
    span {
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.7);
    }

    @include tablet {
      gap: 0.2rem;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .purchaseDetail {
    font-weight: 600;
    font-size: 0.9rem;
    text-decoration: underline;
  }
}
