.user-detail-modal {
  .content {
    @include media(">=tablet") {
      //max-width: 60vw;
    }
  }
  .user-detail-content {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    hr {
      margin-top: 1rem;
    }
    h2 {
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }
    span {
      font-size: 1.2rem;
    }
  }
}

// Designer configuration page
.designer-config {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background: white;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  .user-info {
    display: flex;
    margin-top: 1rem;
    .user-info-text {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    .portfolio-images-upload {
      display: flex;
      flex-wrap: wrap;
      div {
        position: relative;
        margin: 0 1rem;
      }
      img {
        width: 200px;
        height: 200px;
        object-fit: cover;
      }
      .delete-image-btn {
        color: white;
        cursor: pointer;
        text-align: center;
        background-color: lightcoral;
        border-radius: 100px;
        font-size: 24px;
        position: absolute;
        width: 32px;
        right: -10px;
        top: 5px;
      }
    }
    input[type="file"] {
      display: none;
    }
    .upload-image-btn {
      width: fit-content;
      i {
        margin-right: 1rem;
      }
    }
    input,
    textarea,
    button,
    select,
    img {
      margin: 1rem 0;
    }
    label {
      color: #24292e;
      font-weight: 600;
      margin-top: 1rem;
    }
    button,
    .upload-image-btn {
      background-color: #fdd400;
      color: black;
      padding: 0.5rem 1.5rem;
      border-radius: 10px;
      text-decoration: none;
      font-weight: 600;
      text-align: center;
      cursor: pointer;
      font-size: 1rem;
      font-family: "Open sans";
      border: none;
    }
    textarea,
    input,
    select {
      font-family: "Open sans";
      padding: 0.25rem 1rem;
      margin-top: 0.5rem;
    }
    textarea::placeholder,
    input {
      font-family: "Open sans";
      color: $dark_grey;
      font-size: 1rem;
    }
    .name-fields {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      div {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }
    }
  }
}

// LSH
.legal-doc {
  display: none;
}
.receipt-modal {
  .content {
    max-width: 50vw;
    @include media("<=tablet") {
      max-width: 90vw;
    }
    .receipt-modal-content {
      form {
        display: flex;
        flex-direction: column;
        input,
        textarea,
        button,
        select {
          margin: 1rem 0;
        }
        label {
          color: #24292e;
          font-weight: 600;
          margin-top: 1rem;
        }
        button {
          background-color: #fdd400;
          color: black;
          padding: 0.5rem 1.5rem;
          border-radius: 10px;
          text-decoration: none;
          font-weight: 600;
          text-align: center;
          cursor: pointer;
          font-size: 1rem;
          font-family: "Open sans";
          border: none;
        }
        input,
        select {
          font-family: "Open sans";
          padding: 0.25rem 1rem;
          margin-top: 0.5rem;
        }
        input {
          font-family: "Open sans";
          color: $dark_grey;
          font-size: 1rem;
        }
        .name-fields {
          display: flex;
          flex-wrap: wrap;
          gap: 1rem;
          div {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
          }
        }
      }
    }
  }
}
