.container {
  font-family: "Poppins", sans-serif;
  display: flex;
  gap: 0.8rem;
  .icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #f8bd43;
    color: #303030;

    svg {
      font-size: 1.5rem;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 250px;

    h5 {
      font-size: clamp(0.8rem, 1vw, 0.9rem);
      font-weight: bold;
      color: #303030;
    }
  }
}
