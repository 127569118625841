@import "../../../../ecommerce/styled-components/mixins.scss";
.item {
  width: 100%;
  border-radius: 6px;
  margin-bottom: 16px;
  font-family: "Poppins", sans-serif;

  .content {
    display: flex;
    flex-direction: column;
    line-height: 18px;
    padding: 16px;
    text-align: left;
    width: 100%;

    @include tablet {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .itemProduct {
      display: flex;
      width: 100%;
      @include tablet {
        width: 80%;
      }
      .itemImage {
        height: 72px;
        width: 72px;
        min-width: 72px;
        min-height: 72px;
        position: static;
        overflow: hidden;
        img {
          border: 1px solid rgba(0, 0, 0, 0.1);
          -webkit-border-radius: 6px;
          border-radius: 6px;
          height: auto;
          width: 100%;
        }
      }
      .itemData {
        margin-left: 16px;

        .itemIntro {
          font-size: 14px;
          font-weight: 600;
          margin: 0 0 6px;
          color: #00a650;
        }
        .itemDate {
          color: rgba(0, 0, 0, 0.9);
          font-size: 14px;
          font-weight: 400;
          margin: 0 0 6px;
          line-height: 18px;
          text-align: left;
        }
        .itemTitle {
          color: rgba(0, 0, 0, 0.7);
          overflow: hidden;
          text-decoration: none;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
          text-align: left;
        }
        .itemInfo {
          color: rgba(0, 0, 0, 0.55);
          overflow: hidden;
          text-decoration: none;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          text-align: left;
        }

        .itemPrice {
          color: rgba(0, 0, 0, 0.7);
          overflow: hidden;
          text-decoration: none;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
          text-align: left;
        }
      }
    }
    .buttonContainer {
      width: 100%;
      margin-top: 16px;
      display: flex;
      gap: 1rem;
      flex-direction: column;
      justify-content: center;

      a {
        text-decoration: none;
        color: white;
      }

      @include tablet {
        width: 20%;
        margin-top: 0;
        align-items: flex-end;
      }
    }
  }
}
