// step-0 work-with-us
.work-with-us {
  .call-to-action {
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      font-size: 1.5rem;
      cursor: pointer;
      background-color: $yellow;
      color: black;
      padding: 0.7rem 4rem;
      border-radius: 10px;
      text-decoration: none;
      font-weight: 600;
    }
  }
  .slick-slider {
    display: flex;
    flex-direction: column-reverse;
    .slick-dots {
      position: static;
      margin-top: 1rem;
      li {
        width: auto;
        margin: 0 0.8rem 0.8rem 0.8rem;
      }
    }
    .slick-dots-btn {
      a {
        text-decoration: none;
        color: grey;
        text-transform: uppercase;
      }
    }
		// no column slide for large text or tables
		.slide-container-no-col {
			td {
				color: #adb0b1;
				font-family: 'Open sans';
				font-size: 1rem;
			}
			th {
				color: #333333;
				font-size: 1.25rem;
			}
			caption {
				font-family: 'Open sans';
			}
      .slide-text {
				margin: 1rem;
        h1 {
					margin-bottom: 0.5rem;
          font-size: 2rem;
          font-family: "Open sans";
          font-weight: 300;
        }
        p {
          text-align: justify;
          color: #adb0b1;
					margin-bottom: 1.5rem;
        }
      }
		}
    .slide-container {
      display: flex!important;
      font-weight: 300;
      @include media("<=LGphone") {
        flex-direction: column;
      }
      img {
        @include media("<=LGphone") {
          width: auto;
          max-height: 300px;
          padding: 0 2rem;
        }
        width: 250px;
        object-fit: cover;
        height: 480px;
        flex: 1.25;
        padding: 2rem;
      }
      .slide-text {
        padding: 2rem;
        text-align: justify;
        flex: 1;
        h1 {
          font-size: 2rem;
          font-family: "Open sans";
          font-weight: 300;
          margin-bottom: 1.5rem;
        }
        p {
					font-size: 1.25rem;
          text-align: justify;
          margin-bottom: 1rem;
          color: #adb0b1;
        }
      }
    }
  }
  .container {
    display: flex;
    width: 100%;
    justify-content: center;
    height: 80vh;
    margin-bottom: 8rem;
    @include media("<=LGphone") {
      height: 30vh;
    }
    .bg-image {
      display: flex;
      width: 100%;
      padding: 5% 10%;
      background: url("https://cdn.apartmenttherapy.info/image/upload/f_auto,q_auto:eco,c_fit,w_1392,h_929/at%2Farchive%2F479efefe82bc75379c4474550eb18d5592c7dd5b")
        no-repeat;
      background-size: cover;
      box-shadow: inset 0 0 0 1000px rgba(250, 201, 0, 0.8);
    }
    .content {
      width: 100%;
      height: auto;
      margin-bottom: -5%;
      h1 {
        color: white;
        font-family: "Open sans";
        line-height: 1;
        font-size: 2.5rem;
        span {
          font-size: 1.8rem;
        }
        @include media("<=LGphone") {
          font-size: 2rem;
        }
      }
      iframe {
        margin-top: 1rem;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.6),
          0 22px 70px 4px rgba(0, 0, 0, 0.26), 0 0 0 1px rgba(0, 0, 0, 0);
      }
    }
  }
}
