.related-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;

  .related-content {
    width: 100%;
    @include tablet {
      width: 90%;
      margin-bottom: 0;
    }
  }

  h1 {
    font-size: clamp(1rem, 2vw, 1.25rem);
    font-weight: 700;
    color: #4f4f4f;
    margin-bottom: 1.5rem;
  }
}
