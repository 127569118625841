.container {
  max-width: 90rem;
  margin-inline: auto;
  padding-inline: 1rem;

  @media (min-width: 640px) {
    padding-inline: 1.5rem;
  }
  @media (min-width: 1024px) {
    padding-inline: 2rem;
  }
}

.section {
  padding-block: 2rem;

  @include tablet {
    padding-block: 5rem;
  }
}

.margin-block {
  margin-block: 1.5rem;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hr-separator {
  border: none;
  width: 100%;
  height: 1px;
  background-color: rgba(145, 158, 171, 0.2);
  margin: 20px 0;
}

.paper-container {
  padding: 1rem;
  margin-inline: auto;
  width: auto;
  width: 100%;

  @include tablet {
    padding: 2rem;
  }
}
