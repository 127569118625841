@import "../../../../../../ecommerce/styled-components/mixins.scss";
.searchInput {
  position: relative;
  width: 100%;
  height: 40px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 14px;
  color: #333;
  outline: none;
  transition: all 0.3s ease;

  @include desktop {
    width: 300px;
  }

  .searchIcon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #333;
    font-size: 1.5rem;
  }

  .searchInput {
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 14px;
    color: #333;
    margin-left: 30px;
  }
}
