.bg-icon-whatsapp {
  margin: 0px;
  top: auto;
  right: 20px;
  bottom: 20px;
  left: auto;
  position: fixed;
  z-index: 999;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  background-color: #25d366;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: none;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  font-size: 24px;
  transition: background-color 0.5s ease-in-out;
  &:hover {
    background-color: #0d8c79;
  }
}
