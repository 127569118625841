.measuresFormInfo {
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 0.5rem;
  width: 100%;
  margin-bottom: 1.5rem;
}

.saveButton {
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
