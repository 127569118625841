// component ImageForm

.imageFormContainer {
  display: flex;
  align-items: center;
  align-items: flex-start;
  gap: 4rem;
  width: 100%;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 2rem;
  }

  h3 {
    align-self: start;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  ul {
    align-self: flex-start;
    margin-bottom: 1rem;
    padding-left: 1rem;
    p {
      font-size: 0.875rem;
      font-weight: 400;
      color: rgb(99, 115, 129);
    }
  }

  .formItem {
    width: 100%;
    max-width: 40rem;
  }
}

// MultipleFileUploader Component

.imageDropperContainer {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  outline: none;
  border-radius: 8px;
  overflow: hidden;
  background-color: rgba(145, 158, 171, 0.08);
  border: 1px dashed rgba(145, 158, 171, 0.2);
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.fileUploadContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  font-family: "Poppins", sans-serif;

  .fileUploadImage {
    width: 10rem;
    height: 10rem;
    border: 1px dashed rgba(145, 158, 171, 0.2);
    padding: 8px;
    overflow: hidden;
    cursor: pointer;

    .fileUploadPlaceholder {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
      justify-content: center;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 8;
      position: absolute;
      color: rgb(145, 158, 171);
      background-color: rgb(30 114 198 / 8%);
      transition: 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      opacity: 1;

      &:hover {
        opacity: 0.7;
      }

      .svg {
        width: 32px;
        height: 32px;
      }

      span {
        font-weight: 400;
        line-height: 1.5;
        font-size: 0.75rem;
      }
    }

    .fileUploadPlaceholderError {
      color: rgb(255, 86, 48);
      background-color: rgba(255, 86, 48, 0.1);
    }

    .fileUploadArea {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;

      &:hover .fileUploadHover {
        opacity: 1;
      }

      .filePreview {
        overflow: hidden;
        position: relative;
        vertical-align: bottom;
        display: inline-block;
        width: 100%;
        height: 100%;

        .filePreviewImage {
          width: 100%;
          height: 100%;
          vertical-align: bottom;
          background-size: cover !important;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            vertical-align: bottom;
            opacity: 1;
            transition: opacity 0.3s;
          }
        }
      }

      .fileUploadHover {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 9;
        position: absolute;
        color: rgb(255, 255, 255);
        background-color: rgba(36, 22, 22, 0.64);
        transition: 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        opacity: 0;

        .svg {
          width: 32px;
          height: 32px;
        }

        span {
          font-weight: 400;
          line-height: 1.5;
          font-size: 0.75rem;
        }
      }
    }
  }
  .fileUploadInstructions {
    margin: 15px auto 0px;
    line-height: 1.5;
    font-size: 0.75rem;
    font-weight: 400;
    color: rgb(145, 158, 171);
    display: block;
    text-align: center;
  }

  .fileNameDisplay {
    margin: 15px auto 0px;
    line-height: 1.5;
    font-size: 0.75rem;
    font-weight: 400;
    color: rgb(145, 158, 171);
    display: block;
    text-align: center;
  }

  .singleFileLable {
    margin: 0px 0px 4px;
    font-weight: 600;
    line-height: 1.57143;
    font-size: 0.875rem;
    text-align: center;
    color: #4f5c69;
    margin-top: 1rem;
  }

  .error {
    border: 1px dashed rgb(255, 86, 48);
  }
}

.fileUploadedContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 24px;
}

.fileUploadedBox {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid rgba(145, 158, 171, 0.16);
  margin: 4px;
}

.fileUploaderImg {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  object-fit: cover;
  position: absolute;
}

.fileUploadedBox button {
  position: absolute;
  top: 4px;
  right: 4px;
  color: rgb(255, 255, 255);
  border: none;
  cursor: pointer;
  background-color: rgba(22, 28, 36, 0.48);
  padding: 4px;

  &:hover {
    background-color: rgba(22, 28, 36, 0.68);
  }

  .deleteIcon {
    width: 14px;
    height: 14px;
  }
}

.fileUploaderBox {
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  height: 15rem;
  @media (min-width: 1024px) {
    height: 300px;
  }

  img {
    width: 100%;
    max-width: 150px;
  }

  h3 {
    font-size: clamp(0.8rem, 2vw, 1.1rem);
    font-weight: 700;
    color: #3f3f3f;
    margin-bottom: 1rem;
    align-self: center;
  }
  p {
    font-size: clamp(0.6rem, 2vw, 0.8rem);
    color: #3f3f3f;
    font-weight: 400;
    color: rgb(99, 115, 129);
    text-align: center;

    span {
      margin-left: 4px;
      margin-right: 4px;
      color: #3f51b5;
      text-decoration: underline;
    }
  }
}

.errorMessage {
  border: 1px dashed rgb(255, 86, 48);
  background-color: rgba(255, 86, 48, 0.08);
  h3 {
    color: rgb(255, 86, 48);
  }
}
.errorMessageImage {
  color: rgb(255, 86, 48);
  font-size: 0.75rem;
  line-height: 1.5;
  margin-top: 8px;
  font-weight: 400;
}
