@import "../../../../../ecommerce/styled-components/mixins.scss";

.orderHeaderDetail {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
  overflow: hidden;
  padding: 1.5rem;

  @include tablet {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-inline: 2rem;
  }
}
