.inputValidationMessage {
  width: 100%;
  margin-top: 5px;
  p {
    font-family: "Poppins", sans-serif;
    color: #fa6e61;
    line-height: 1.4;
    font-size: 0.75rem;
    text-align: left;
    font-weight: 500;
    width: 100%;
  }
}
