.discounted-products {
  width: 100%;
  h1 {
    font-size: clamp(1.5rem, 2vw, 1.7rem);
    font-weight: 600;
    margin-bottom: 3rem;
    text-align: center;
    color: var(--text-color);
  }
}
