.container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.9rem;
  padding-right: 0.5rem;

  .prueba {
    background-color: aqua;
  }
}
