.showError {
  background-color: rgba(255, 76, 76, 0.15);
  display: flex;
  width: 100%;
  align-items: center;
  padding-block: 12px;
  padding-inline: 20px;
  border-radius: 4px;
  gap: 0.4rem;
  margin-top: 4px;
  svg {
    color: rgb(255, 76, 76);
    font-size: 2rem;
  }
  span {
    display: block;
    color: rgb(252, 100, 100);
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.5px;
  }
}
