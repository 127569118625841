// Admin containers
.admin-container{
    display: flex;
    .left-half{
        flex:1;
        padding:1rem;
    }
    .right-half{
        flex:1;
        padding:1rem;
    }
    
    li{
        padding: 10px 0px 10px 10px;
        display: list-item;
        text-align: left;
        vertical-align: center;
    }

    #money{
        list-style-image: url("../gif/money.png");
    }
    #calendar{
        list-style-image: url("../gif/calendar.png");
    }

    #clients{
        list-style-image: url("../gif/clients.png");
    }

    #effective{
        list-style-image: url("../gif/effective.png");
    }

    #admin-arrow{
        list-style-image: url("../gif/admin-arrow.png");
    }

    #profits-chart{
        margin-top: 15%;
    }

    #last-month{
        list-style-image: url("../gif/calendar.png");
        margin-bottom: 5%;
    }
}

