.checkboxContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}

.checkbox {
  width: 18px;
  height: 18px;
  border: 2px solid #ccc;
  border-radius: 3px;
  margin-right: 10px;
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
  }
}

.checkboxLabel {
  font-size: 14px;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.7);

  a {
    color: #fdd440;
    text-decoration: none;
    text-decoration: underline;
  }
}
