@import "../../../../../../ecommerce/styled-components/mixins.scss";
.purchasesData {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  font-family: "Poppins", sans-serif;

  .title {
    flex-basis: 100%;
    font-size: 24px;
    line-height: 1;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.9);
  }
  .purchaseDate {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5rem;
    div:nth-child(1) {
      display: flex;
      flex-direction: column;

      @include tablet {
        flex-direction: row;
        align-items: center;
        gap: 1rem;
      }
    }
    span {
      color: rgba(0, 0, 0, 0.55);
      font-size: 14px;
      font-weight: 600;
    }

    @include tablet {
      flex-direction: row;
      align-items: center;
      gap: 1rem;
    }
  }

  .invoiceInformation {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: #fff3cd;
    border-radius: 0.5rem;
    margin-top: 1rem;
    color: #737373;
    font-size: 14px;
    font-weight: 300;
    padding-block: 0.5rem;
    padding-inline: 0.5rem;
  }
  .purchaseDetails {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 6px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    margin-bottom: 16px;
    padding: 1rem;
    > *:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    }
    @include tablet {
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      align-items: flex-start;
      padding-inline: 2rem;
      gap: 1rem;
      > *:not(:last-child) {
        border-bottom: 0;
      }
    }

    h3 {
      color: rgba(53, 53, 53, 0.9);
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.25;
      text-align: left;
    }
    .addressSection {
      padding-block: 0.7rem;
      display: flex;
      flex-direction: column;

      @include tablet {
        width: 30%;
      }
      @include desktop {
        width: 30%;
      }
      span {
        color: rgba(0, 0, 0, 0.55);
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
      }
    }

    .paymentSection {
      padding-block: 0.7rem;
      display: flex;
      flex-direction: column;

      @include tablet {
        width: 30%;
      }
      @include desktop {
        width: 30%;
      }
      span {
        color: rgba(0, 0, 0, 0.55);
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
      }
    }
    .orderSummarySection {
      padding-block: 0.7rem;

      @include tablet {
        width: 30%;
      }
      @include desktop {
        width: 30%;
      }
      hr {
        background: rgba(0, 0, 0, 0.1);
        height: 1px;
        width: 100%;
        margin: 16px 0;
        border: none;
      }
      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        margin-bottom: 12px;
        span {
          color: rgba(0, 0, 0, 0.55);
          font-size: 14px;
          font-weight: 500;
          line-height: 1.5;
        }
      }
    }
  }
}
