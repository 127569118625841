// login
.login {
  display: flex;
  height: 100vh;
  @include media("<=tablet") {
    flex-direction: column;
  }
  .image {
    background-image: url("https://connect-homes.com/wp-content/uploads/2020/01/2-1600x1600-c-center.jpg");
    background-size: cover;
    flex: 1;
  }
  h1 {
    margin-top: 2rem;
    text-align: center;
    color: #505659;
    font-size: 2.5rem;
  }
  .form {
    flex: 1;
    background-color: $almost_white;
    form {
      display: flex;
      padding: 1rem 4rem 4rem 4rem;
      flex-direction: column;
      align-items: center;
      @include media("<=phone") {
        padding: 2rem;
      }
    }
    select {
      font-family: "Open sans";
      font-size: 1rem;
      width: 100%;
      color: grey;
      border: none;
      margin: 1rem 0.5rem;
      text-align-last: center;
      background: none;
      border-bottom: 1px solid black;
    }
    input,
    textarea {
      margin: 1rem 0.5rem;
      width: 100%;
      padding: 0.25rem 1rem;
      border: none;
      border-bottom: 1px solid black;
      background-color: $almost_white;
    }
    input:focus,
    textarea:focus {
      outline: 1px solid $yellow;
    }
    input::placeholder,
    textarea::placeholder {
      font-family: "Open sans";
      color: $dark_grey;
      text-align: center;
      font-size: 1rem;
    }
    #tandc {
      width: auto;
    }
  }
}
