.box-drag-drop {
  background-color: #fff;
  padding: 10px;
  border-radius: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  max-width: 330px;
  width: 330px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.drop-file-input {
  width: 100%;
  position: relative;
  height: 200px;
  border: 2px dashed #4267b2;
  border-radius: 20px;
  margin-bottom: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #f5f8ff;
}

.drop-file-input-error {
  border: 2px dashed rgb(255, 86, 48);
  background-color: rgba(255, 86, 48, 0.08);
  h3 {
    color: rgb(255, 86, 48);
  }
}

.drop-file-input input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.drop-file-input input:disabled {
  cursor: no-drop;
}

.drop-file-input:hover,
.drop-file-input.dragover {
  opacity: 0.6;
}

.drop-file-input__label {
  text-align: center;
  color: #ccc;
  font-weight: 600;
  padding: 10px;
}

.drop-file-input__label img {
  width: 100px;
}

.drop-file-preview {
  margin-top: 30px;
}

.drop-file-preview p {
  font-weight: 500;
}

.drop-file-preview__title {
  margin-bottom: 20px;
}

.drop-file-preview__item {
  position: relative;
  display: flex;
  margin-bottom: 10px;
  background-color: #f5f8ff;
  padding: 15px;
  border-radius: 20px;
  align-items: center;
}

.drop-file-preview__item img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
  border-radius: 10px;
}

.drop-file-preview__item__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 11rem;
}

.drop-file-preview__item__del {
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.drop-file-preview__item:hover .drop-file-preview__item__del {
  opacity: 1;
}

.item-name {
  width: 100%;
  word-wrap: break-word;
}
