.inputContainer {
  width: 100%;
  position: relative;
  padding-top: 20px;
  .inputContent {
    position: relative;
    .inputIcon {
      position: absolute;
      top: 50%;
      left: 12px;
      color: rgba(255, 255, 255, 0.7);
      transform: translateY(-50%);
      font-size: 1.3rem;
    }
    input {
      background-color: transparent;
      width: 100%;
      font-family: inherit;
      font-weight: 400;
      color: rgb(255, 255, 255);
      height: 45px;
      border-width: 0.8px;
      border-style: solid;
      border-radius: 0.375rem;
      border-color: rgba(255, 255, 255, 0.4);
      font-size: 1rem;
      letter-spacing: 0px;
      padding-left: 2.3rem;
      outline: 0;
      &::placeholder {
        color: rgba(255, 255, 255, 0.7);
      }
      &.error {
        border-color: rgb(255, 76, 76);
      }
    }

    input:-webkit-autofill {
      -webkit-text-fill-color: white;
      -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.6) inset;
    }

    .passwordIcon {
      display: flex;
      align-items: center;
      position: absolute;
      color: rgba(255, 255, 255, 0.7);
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      svg {
        font-size: 1.4rem;
      }
    }
  }
}
.errorContent {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  margin-top: 4px;
  svg {
    color: rgb(255, 76, 76);
    font-size: 1rem;
  }
  span {
    display: block;
    color: rgb(255, 76, 76);
    font-family: inherit;
    font-weight: 600;
    font-style: normal;
    font-size: 12px;
    letter-spacing: 0px;
    line-height: 18px;
  }
}
