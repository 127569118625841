.selectContainer {
  position: relative;
  width: 100%;
  margin-top: 1rem;

  h3 {
    color: #999;
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 0.4rem;
  }
}

.selectInput {
  width: 100%;
  height: 45px;
  padding: 8px;
  font-family: inherit;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  appearance: none;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E")
    no-repeat right 8px center/16px 16px;
  background-color: #fff;
  cursor: pointer;
  &.error {
    border-color: rgb(255, 76, 76);
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
  }

  & option[disabled] {
    color: #999;
  }
}

.errorContent {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  margin-top: 4px;
  svg {
    color: rgb(255, 76, 76);
    font-size: 1rem;
  }
  span {
    display: block;
    color: rgb(255, 76, 76);
    font-family: inherit;
    font-weight: 600;
    font-style: normal;
    font-size: 12px;
    letter-spacing: 0px;
    line-height: 18px;
  }
}
