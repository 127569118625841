.containerHeader {
  margin-bottom: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.backgroundHeader {
  background-color: #a3afc7;
  color: #fff;
  font-size: 1rem;
}

.backgroundRowOne {
  background-color: #f4f6f8;
}
