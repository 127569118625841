.virtual-consultancy {
  background-color: #cacaca;
  margin-top: 2rem;
  @include tablet {
    @include flexible(flex, row, nowrap, center, center);
  }

  &__title {
    @include flexible(flex, column, nowrap, center, center);
    padding: 2rem 1rem 3rem;
    @include desktop {
      width: 70%;
      height: 100%;
    }

    div {
      @include tablet {
        max-width: 80%;
      }
      @include desktop {
        max-width: 60%;
      }
      h3 {
        color: #4f4f4f;
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 1.2;
        margin-bottom: 0.8em;
        text-transform: uppercase;
      }
      p {
        font-size: 1rem;
        color: #4f4f4f;
        font-weight: 400;
        margin-bottom: 1.5rem;
      }
    }
  }

  &__video {
    @include flexible(flex, row, nowrap, center, center);
    width: 100%;
    height: 30vh;
    @include tablet {
      min-height: 100%;
    }
    @include desktop {
      width: 35%;
    }

    &__iframe {
      width: 90%;
      height: 90%;

      @include tablet {
        width: 80%;
        height: 80%;
      }

      @include desktop {
        width: 85%;
        height: 85%;
      }
    }
  }
}
