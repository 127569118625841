@import "../../../../ecommerce/styled-components/mixins.scss";

.orderItem {
  width: 100%;
  border-radius: 6px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  margin-bottom: 16px;

  .content {
    width: 100%;

    > *:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    @include tablet {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-inline: 1rem;
    }

    .heading {
      padding-inline: 1rem;
      padding-top: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .status {
        @include tablet {
          width: 80%;
        }
        .deliveryDate {
          font-size: 0.9rem;
          color: rgba(0, 0, 0, 0.7);
          font-weight: 600;
        }
      }
      .trackButton {
        display: flex;
        justify-content: flex-end;
        @include tablet {
          width: 20%;
        }
      }
    }
  }

  .statusDelivered {
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 6px;
    color: #00a650;
  }
  .statusPending {
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 6px;
    color: #ff684e;
  }
}
