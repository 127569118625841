@import "../../../../ecommerce/styled-components/mixins.scss";

.purchasesPage {
  &__header {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    h2 {
      flex-basis: 100%;
      margin: 0;
      font-size: 24px;
      line-height: 1;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.9);
      margin-bottom: 2rem;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3rem;

  @include desktop {
    flex-direction: row;
    align-items: center;
  }

  div {
    display: flex;
    align-items: center;
    gap: 1rem;

    h3 {
      color: rgba(0, 0, 0, 0.55);
      font-size: 14px;
      font-weight: 400;
      margin: 0;
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-top: 15em;
}

.noPurchases {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  padding-top: 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.55);
}
