@import "../../../../ecommerce/styled-components/mixins.scss";
.mainContainer {
  font-family: "Poppins", sans-serif;
  @include desktop {
    display: block;
    padding: 40px 92px;
  }

  .productReviewPage {
    max-width: 40rem;
    margin-inline: auto;
    .title {
      flex-basis: 100%;
      margin: 0;
      font-size: 24px;
      line-height: 1;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.9);
      margin-bottom: 2rem;
    }

    h2 {
      flex-basis: 100%;
      margin: 0;
      font-size: 24px;
      line-height: 1;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.9);
    }
    .productReviewForm {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
      hr {
        background: rgba(0, 0, 0, 0.1);
        height: 1px;
        width: 100%;
        margin: 16px 0;
        border: none;
      }
      .product {
        display: flex;
        align-items: center;
        gap: 1rem;

        .productImage {
          height: 72px;
          min-width: 72px;
          position: static;
          width: 72px;
          img {
            border: 1px solid rgba(0, 0, 0, 0.1);
            -webkit-border-radius: 6px;
            border-radius: 6px;
            height: auto;
            width: 100%;
          }
        }
      }

      form {
        width: 100%;
      }

      .rating {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        gap: 0.5rem;
      }

      .addTitle {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        gap: 0.5rem;

        input {
          width: 100%;
          height: 40px;
          border-radius: 6px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          padding: 0 1rem;
          font-size: 1rem;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.9);
          line-height: 22px;
          &:focus {
            outline: 0.1rem solid rgba(0, 0, 0, 0.3);
          }

          &::placeholder {
            color: rgba(0, 0, 0, 0.55);
            font-size: 14px;
            font-weight: 400;
            font-family: "Poppins", sans-serif;
          }
        }
      }

      .addReview {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        textarea {
          width: 100%;
          height: 120px;
          border-radius: 6px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          padding: 1rem;
          font-size: 14px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.9);
          // make a color border when the textarea is focused
          &:focus {
            outline: 0.1rem solid rgba(0, 0, 0, 0.3);
          }
          //make styles to placeholder
          &::placeholder {
            color: rgba(0, 0, 0, 0.55);
            font-size: 14px;
            font-weight: 400;
            font-family: "Poppins", sans-serif;
          }
        }
      }

      .buttonSubmit {
        margin-top: 1.5rem;
      }
    }
  }
}
