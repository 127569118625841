@import "../../ecommerce/styled-components/mixins.scss";
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(51, 51, 51, 0.8);
  padding-inline: 10px;
  padding-block: 20px;
  width: 90%;
  max-width: 450px;
  margin-inline: auto;

  @include tablet() {
    padding-block: 30px;
    padding-inline: 30px;
    width: 100%;
  }
  .title {
    font-family: inherit;
    font-weight: 600;
    font-style: normal;
    font-size: 1.5rem;
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
  }
  .form {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 300px;

    .forgotPassword {
      margin-block: 0.8rem;
      width: 100%;
      text-align: left;
      text-decoration: underline;
      text-decoration-color: rgb(255, 255, 255);

      span {
        cursor: pointer;
        color: rgb(255, 255, 255);
        font-size: 0.9rem;
        text-decoration: none;
        transition: all 300ms ease-in-out;
        &:hover {
          color: rgb(209, 209, 209);
        }
      }
    }
  }
  .footerContent {
    margin-top: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    .text {
      width: 100%;
      text-align: left;
      span {
        color: rgb(255, 255, 255);
        font-size: 0.9rem;
        text-decoration: none;
        text-align: left;
      }
    }
  }
}
