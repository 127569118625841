.container {
  font-family: "Poppins", sans-serif;
  background-color: #f8bd43;
  padding: 1rem;
  display: flex;
  justify-content: space-between;

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.4rem;
    .orderDate {
      display: flex;
      align-items: center;
      gap: 0.4rem;

      svg {
        font-size: 1.2rem;
      }
      h3 {
        font-size: 1rem;
        font-weight: bold;
        color: #303030;
        line-height: 1.2;
      }
    }
    p {
      font-size: 0.8rem;
      font-weight: 500;
      color: #2b2b2b;
    }
  }

  .contentActions {
    display: flex;
    gap: 1rem;
  }
}
