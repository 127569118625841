.product-information {
  background-color: var(--bg-color);
  .productBox {
    width: 100%;
    gap: 1em;
    @include flexible(flex, column, nowrap, center, center);
    @include desktop {
      @include flexible(flex, row, nowrap, center, flex-start);
      gap: 1rem;
    }

    .productImageGallery {
      position: relative;
      width: 100%;
      @include tablet {
        width: fit-content;
      }
    }

    // Images section
    &__boxImages {
      width: 100%;
      @include desktop {
        width: 50%;
      }
    }

    // Features and shopping cart button section
    &__features {
      width: 100%;
      @include flexible(flex, column, nowrap, center, center);
      @include desktop {
        width: 50%;
      }

      &__virtualConsultancy {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 21em;
        margin-block: 1em;
        @include flexible(flex, row, nowrap, space-between, center);

        button {
          margin-top: 0em;
          margin-left: 0.8em;
        }

        p {
          font-size: 14px;
          text-align: left;
          line-height: 1em;
        }

        @include tablet {
          width: 25em;

          p {
            line-height: 1.2em;
          }
        }

        @include desktop {
          width: 25em;

          p {
            line-height: 1.5em;
          }
        }
      }
    }
  }
}
